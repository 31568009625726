import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import Vuex from 'vuex'

Vue.use(VueRouter)
Vue.use(Vuex)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: 'active',
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

router.beforeResolve(async (to, from, next) => {
  if (to.path === from.path) {
    window.location.reload()
    return
  }

  if (to.name) {
    // this.$flex.progress.start()
    const pageTitle = router.app.$options.i18n.t(`labels.${to.name}`)
    document.title = `${pageTitle} | ${process.env.VUE_APP_TITLE}`
  }
  const userLang = window.localStorage.getItem('user_lang')
  if (userLang) {
    router.app.$options.i18n.locale = userLang
  }

  const isAuthenticated = router.app.$store.getters.isAuthenticated

  const checkActive = async () => {
    return router.app.$http.get('/networkApp/checkActiveStatus')
  }

  if (to.name !== 'login' && from.name !== 'login' && isAuthenticated) {
    const isActive = await checkActive()
    if (!isActive) {
      router.app.$store.dispatch('logout')
    }
  }

  next()
})

router.afterEach(() => {
  // this.$flex.progress.done()
})

export default router
