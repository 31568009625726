import Vue from 'vue'

import '@gameflexteam/flex-admin-vue/dist/flex-admin-vue.min.css'

import i18n from '@/plugins/i18n'
import apiConfig from './apiConfig'

import {
  CoreComponentsPlugin,
  DatatablePlugin,
  RecaptchaPlugin,
  ProgressPlugin,
  SwitchPlugin,
  UploadPlugin,
  JwtPlugin,
  NotificationPlugin,
  ApiPlugin
} from '@gameflexteam/flex-admin-vue'

Vue.use(CoreComponentsPlugin, {
  validate: {
    messages: {
      confirmed: i18n.t('validation.confirmed'),
      date_after: i18n.t('validation.date_after'),
      email: i18n.t('validation.email'),
      integer: i18n.t('validation.integer'),
      length: i18n.t('validation.length'),
      max: i18n.t('validation.max'),
      min: i18n.t('validation.min'),
      password: i18n.t('validation.password'),
      required: i18n.t('validation.required')
    }
  }
})

Vue.use(DatatablePlugin, {
  locale: i18n.locale,
  labels: {
    create: i18n.t('labels.new'),
    delete: i18n.t('labels.delete'),
    edit: i18n.t('labels.edit'),
    actions: i18n.t('labels.actions'),
    cancel: i18n.t('labels.cancel')
  },
  messages: {
    errorOccurred: i18n.t('messages.errorOccurred'),
    deleteConfirmMessage: i18n.t('messages.deleteConfirmMessage')
  }
})

Vue.use(ProgressPlugin)

Vue.use(SwitchPlugin)

Vue.use(UploadPlugin)

Vue.use(JwtPlugin)

Vue.use(NotificationPlugin)

Vue.use(ApiPlugin, apiConfig)

if (process.env.NODE_ENV === 'production') {
  Vue.use(RecaptchaPlugin, {
    // eslint-disable-next-line spellcheck/spell-checker
    siteKey: '6LdPDiQaAAAAAD_wc_O2arR6rciEMjTpK7I6fe_9',
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true
    }
  })
}
