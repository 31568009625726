import Vue from '@/main'

// request interceptor handler
const reqCallback = config => {
  if (config.method !== 'get') {
    Vue.$flex.progress.start()
  }
  const token = Vue.$flex.jwt.getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}

const reqErrorCallback = error => {
  Vue.$flex.progress.done()
  return Promise.reject(error)
}

// response interceptor handler
const resCallback = response => {
  Vue.$flex.progress.done()
  const { data } = response
  return data
}

const resErrorCallback = error => {
  Vue.$flex.progress.done()
  let errorMessage = Vue.$i18n.t('messages.errorOccurred')

  if (error.response) {
    const res = error.response
    if (res.status === 400) {
      errorMessage = error.response.data.message
    } else if (res.status === 401) {
      Vue.$store.dispatch('logout')
      return
    } else if (res.status === 403) {
      errorMessage = Vue.$i18n.t('messages.forbiddenError')
    }
  }
  Vue.$flex.notification.error(errorMessage)
  return Promise.reject(error)
}

export default {
  rootUrl: process.env.VUE_APP_ROOT_API,
  reqCallback,
  reqErrorCallback,
  resCallback,
  resErrorCallback
}
