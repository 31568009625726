/* eslint-disable */
import i18n from '@/plugins/i18n'

const routes = [
  {
    path: `/${i18n.t('routes.user')}/`,
    component: () => import('@/layouts/user/index.vue'),
    children: [
      {
        path: i18n.t('routes.login'),
        name: 'login',
        component: () => import('@/views/user/Login.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'index',
    component: () => import('@/layouts/dashboard/index.vue'),
    redirect: `${i18n.t('routes.files')}`,
    children: [
      {
        path: `/${i18n.t('routes.files')}`,
        name: 'files',
        component: () => import('@/views/file/Index.vue')
      },
      {
        path: `/${i18n.t('routes.fileDetail')}/:no`,
        name: 'fileDetail',
        component: () => import('@/views/file/fileDetail/Index.vue')
      },
      {
        path: `/${i18n.t('routes.documents')}`,
        name: 'documents',
        component: () => import('@/views/document/Index.vue')
      }
    ]
  }
]

export default routes
